<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="920px">
            <v-card class="elevation-1">
                <ValidationObserver ref="refEditTipoEntrada" v-slot="{ invalid, validated }">
                    <v-form name="form" @submit.prevent="updateTipoEntrada">
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Editar tipo de entrada</v-toolbar-title>
                            <v-spacer></v-spacer>
                             <v-toolbar-items>
                                <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                    <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Actualizar tipo de entrada.
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Nombre*" prepend-icon="fas fa-gopuram"
                                            required v-model="tipoEntrada.nombre"
                                            :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="8">
                                        <ValidationProvider rules="required|min:2|max:5" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Clave*" prepend-icon="fas fa-keyboard" required counter
                                            v-model="tipoEntrada.clave" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Descripción*" prepend-icon="fas fa-pencil-alt" required 
                                            v-model="tipoEntrada.descripcion" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import TipoEntradaService from '@/service/entrada/TipoEntradaService'
export default {
    name: 'EditarTipoEntrada',
    props: {
        dialog: { default: false },
        handleModal: { type: Function },
        tipoEntrada: { type: Object, default: () => {} },
        initialize: { type: Function }
    },
    methods: {
        updateTipoEntrada(){
            this.$refs.refEditTipoEntrada.validate().then( isValid => {
                if(isValid){
                    TipoEntradaService.update(this.tipoEntrada.id, this.tipoEntrada).then( _ => {
                        this.handleModal();
                        this.initialize();
                        this.$swal.fire({ icon: 'success', title: 'Datos actualizados', text: 'Tipo de entrada actualizado', showConfirmButton: false, timer: 2000 });
                    }).catch( error => {
                        this.$swal.fire({ icon: 'error', html: error.response.data, title: 'Error!!', showConfirmButton: false, timer: 2500 });
                    })
                }
            }).catch( error => {
                this.$swal.fire({ icon: 'error', text: error, title: 'Error al actualizar el tipo de entrada', showConfirmButton: false, timer: 2000 });
            })
        }
    }

}
</script>