<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nuevo tipo de entrada
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col cols="12" md="8" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar tipo de entrada por nombre" single-inline hide-details @keydown.enter="searchTipoEntrada">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                    </v-col>
                </v-card-title>
                <v-data-table :headers="headers" :items="tipoEntrada" class="elevation-1"
                :loading="loading" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                :server-items-length="totalTipoEntrada">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon class="mr-2" small @click="showTipoEntrada(item)" v-if="item.activo"> fas fa-edit</v-icon>
                        <v-icon class="mr-2" small @click="removeTipoEntrada(item)" v-if="item.activo"> fas fa-trash-alt</v-icon>
                    </template>
                    <template v-slot:[`item.activo`]="{ item }">
                        <v-chip :color="colorStatus(item.activo)" label dark x-small text-color="white"
                        v-text="item.activo ? 'Activo' : 'Cancelado'">
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <NuevoTipoEntrada :dialog.sync="dialog" :initialize.sync="initTableTipoEntrada" :handleModal="handleModal" v-if="dialog">
        </NuevoTipoEntrada>
        <EditarTipoEntrada :dialog.sync="dialogEdit" :tipoEntrada.sync="info" :initialize.sync="initTableTipoEntrada" :handleModal="handleModalEdit" v-if="dialogEdit">
        </EditarTipoEntrada>
    </v-row>
</template>
<script>
import TipoEntradaService from '@/service/entrada/TipoEntradaService';
import NuevoTipoEntrada from './NuevoTipoEntrada.vue';
import EditarTipoEntrada from './EditarTipoEntrada.vue';
import { getParamsPagination } from '@/utils/pagination';
export default {
    name: 'ListaTipoEntrada',
    components: { NuevoTipoEntrada, EditarTipoEntrada },
    created() {
        this.initTableTipoEntrada();
    },
    data(){
        return {
            tipoEntrada: [],
            options: {},
            totalTipoEntrada: 0,
            search: '',
            loading: false,
            headers: [
                { text: 'Acciones', value: 'actions', sortable: false },
                { text: 'id', align: ' d-none', sortable: false, value: 'id', show: false },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Clave', value: 'clave' },
                { text: 'Descripcion', value: 'descripcion' },
                { text: 'Estatus', value: 'activo' }
            ],
            dialog: false,
            info: [],
            dialogEdit: false,
        }
    },
    methods: {
        searchTipoEntrada(){
            this.options.page = 1;
            this.initTableTipoEntrada(this.options);
        },
        initTableTipoEntrada( options = {} ){
            this.loading = true;
            let params = getParamsPagination({ options, search: this.search })
            TipoEntradaService.getTipoEntradaTable( params ).then( response => {
                const { rows, totalElements } = response.data;
                this.tipoEntrada = rows;
                this.totalTipoEntrada = totalElements;
            }).catch( error => {
                this.$swal.fire({ icon: 'error', text: error, title: 'Error al cargar los tipos de entrada', showConfirmButton: false, timer: 2500 });
            }).finally( () => {
                this.loading = false;
            })
        },
        showTipoEntrada(item){
            this.info = item;
            this.dialogEdit = true;
        },
        removeTipoEntrada(item){
            this.$swal.fire({
                title: '¿Estas seguro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminarlo!',
                cancelButtonText: 'Cancelar',
                iconButtons: true,
            }).then( result => {
                if (result.value) {
                    TipoEntradaService.delete( item.id ).then( response => {
                        this.$swal.fire({  icon: 'success', text: response.data.message, title: 'Tipo de entrada eliminado', showConfirmButton: false, timer: 1500 });
                        this.initTableTipoEntrada();
                    }).catch( error => {
                        this.$swal.fire({ icon: 'error', html: error.response.data, title: 'Error al eliminar el tipo de entrada', showConfirmButton: false, timer: 3500 });
                    });
                }
            }).catch( error => {
                this.$swal.fire({ icon: 'error', html: error, title: 'Error al eliminar el tipo de entrada', showConfirmButton: false, timer: 3500 });
            });
        },
        colorStatus( status ){
            return status ? "green" : "red";
        },
        handleModal(){
            this.dialog = !this.dialog;
        },
        handleModalEdit(){
            this.dialogEdit = !this.dialogEdit;
        }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableTipoEntrada(val);
                }
            }, deep: true
        }
    }
}
</script>