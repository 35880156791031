<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="920px">
            <v-card class="elevation-1">
                <ValidationObserver ref="refNewEntrada" v-slot="{ invalid, validated }">
                    <v-form name="form" @submit.prevent="saveTipoEntrada">
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Nuevo tipo de entrada</v-toolbar-title>
                            <v-spacer></v-spacer>
                             <v-toolbar-items>
                                <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                    <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar tipo de entrada.
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                            <v-text-field label="Nombre*" prepend-icon="fas fa-gopuram"
                                            required v-model="tipoEntrada.nombre"
                                            :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="8">
                                        <ValidationProvider rules="required|min:2|max:5" v-slot="{ errors, valid}">
                                            <v-text-field label="Clave*" prepend-icon="fas fa-keyboard" required counter
                                            v-model="tipoEntrada.clave" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                            <v-text-field label="Descripción*" prepend-icon="fas fa-pencil-alt" required 
                                            v-model="tipoEntrada.descripcion" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <!-- <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="primary" @click="saveTipoEntrada" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar tipo de entrada.
                            </v-btn>
                        </v-card-actions> -->
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import TipoEntradaService from '@/service/entrada/TipoEntradaService';
export default {
    name: 'NuevoTipoEntrada',
    props: {
        dialog: { default: false },
        handleModal: { type: Function },
        initialize: { type: Function }
    },
    data() {
        return {
            tipoEntrada: {
                nombre: '',
                clave: '',
                descripcion: '',
                activo: true
            }
        }
    },
    methods: {
        saveTipoEntrada() {
            this.$refs.refNewEntrada.validate().then( isValid => {
                if( isValid ){
                    TipoEntradaService.create(this.tipoEntrada).then( _ => {
                        this.initialize();
                        this.handleModal();
                        this.$swal.fire({icon: 'success', title: 'Datos correctos', text: 'Tipo de entrada creado', showConfirmButton: false, timer: 1500 });
                    }).catch( error => {
                        this.$swal.fire({ icon: 'error', html: error.response.data, title: 'Error al guardar el tipo de entrada', showConfirmButton: false, timer: 2500 });
                    })
                }
            }).catch( error => {
                this.$swal.fire({ icon: 'error', title: 'Error', text: error.response, show })
            })
        }
    },
}
</script>